import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for VerifyPhoneReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /* Reflex specific lifecycle methods.
   * Use methods similar to this example to handle lifecycle concerns for a specific Reflex method.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Example:
   *
   *   <a href="#" data-reflex="VerifyPhoneReflex#example">Example</a>
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "VerifyPhoneReflex#example"
   *
   *   error - error message from the server
   */

  // beforeUpdate(element, reflex) {
  //  element.innerText = 'Updating...'
  // }

  // updateSuccess(element, reflex) {
  //   element.innerText = 'Updated Successfully.'
  // }

  // updateError(element, reflex, error) {
  //   console.error('updateError', error);
  //   element.innerText = 'Updated Failed!'
  // }
    static targets = [ "phone", "smsCode", "srcPhone", "trigger" ]

    verify(){
        $('#phone_number').val(this.phoneTarget.value)
        this.stimulate('VerifyPhoneReflex#verify', this.phoneTarget.value )
    }
    verify_sms(){
        this.stimulate('VerifyPhoneReflex#verify_sms', this.smsCodeTarget.value )
    }
    afterReflex (element, reflex) {
        console.log(element)
        console.log(reflex)
    }

    // 消费者注册
    update(e){
        e.preventDefault()
        console.log(this.srcPhoneTarget.value)
        console.log(this.srcPhoneTarget.value.length)
        if(this.srcPhoneTarget.value.length != 11){
            alert('输入正确手机号')
            return
        }
        $.ajax({
            method: 'GET',
            url: this.triggerTarget.href,
            data: { phone: this.srcPhoneTarget.value }
        }).done(function(res){
            console.log(res)
        })
    }


}
