import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for SearchReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
    /* Reflex specific lifecycle methods.
     * Use methods similar to this example to handle lifecycle concerns for a specific Reflex method.
     * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
     *
     * Example:
     *
     *   <a href="#" data-reflex="SearchReflex#example">Example</a>
     *
     * Arguments:
     *
     *   element - the element that triggered the reflex
     *             may be different than the Stimulus controller's this.element
     *
     *   reflex - the name of the reflex e.g. "SearchReflex#example"
     *
     *   error - error message from the server
     */

    // beforeUpdate(element, reflex) {
    //  element.innerText = 'Updating...'
    // }

  reflexSuccess (element, reflex, error) {
    // show success message etc...
      initialize_a_remote()
      if(typeof(fetch_chart_data) === 'function' ) {
          fetch_chart_data()
      }
  }
    updateSuccess(element, reflex) {
        console.log('clear success')
    }

    updateError(element, reflex, error) {
        console.error('updateError', error);
    }

    clear(e){
        e.preventDefault()
        this.element.querySelectorAll('select').forEach( ele => {
            ele.querySelector('option').selected = true
        })
        this.element.querySelectorAll('input[type="date"]').forEach( ele => {
            ele.value = ele.getAttribute('data-default')
        })
        this.element.querySelectorAll('input[type="month"]').forEach( ele => {
            ele.value = ele.getAttribute('data-default')
        })
        this.element.querySelectorAll('input[type="number"]').forEach( ele => {
            ele.value = ''
        })
        this.element.querySelectorAll('input[type="text"]').forEach( ele => {
            ele.value = ''
        })
        let session_action = document.getElementById('search_identifier').value
        this.stimulate('SearchReflex#clear', session_action  )
    }

    submit(e){
        pp(e)
        e.preventDefault()
        let params = $(e.target).serializeArray()
        this.stimulate('SearchReflex#update', params  )
    }
}
