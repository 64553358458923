import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["button", "param"]

    initialize(){
    }

    connect(){
        this.data.set('url', this.buttonTarget.getAttribute('href'))

        this.buttonTarget.setAttribute('href', this.to_query())

        this.paramTargets.forEach((el,i)=>{
            el.addEventListener('change', e=> {
                console.log(el.value)
                this.buttonTarget.setAttribute('href', this.to_query())
            })
        })
    }
    to_query(){
        let params = {}
        this.paramTargets.forEach((el,i)=>{
            params[el.name] = el.value
        })
        return this.data.get('url') + '?' + $.param( params )
    }
}
