require("@fortawesome/fontawesome-free/css/all")
require("channels")
require('like-ruby')

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import 'bootstrap'
import "controllers"

import '../stylesheets/main.scss'
import '../wechat/main.scss'

require("jquery-confirm")
require('main')

window.refresh_event = new Event('refresh');
window.jq_confirm = {}
