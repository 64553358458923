import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["src", "output"]

    connect() {
        console.log(this.srcTarget.value)
    }
    update(e){
        console.log(this.srcTarget.value)
        this.outputTarget.innerText = this.srcTarget.value
    }
}
