import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for SelectRegionReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
    get_stores(e) {
        pp(e.target.value)
        this.stimulate('SelectRegionReflex#stores', e.target.value, $('#store_dealer_id').val() )
    }
}
