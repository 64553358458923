// show errors
window.current_dom_id = false
window.initialize_from_remote = function(){
    $('a[data-remote="true"]').on('ajax:error', (event) => {
        console.log(event)
        status = event.detail[2].status
        console.log(status)
        if(status == 403){
            $.alert('权限不足,系统错误!')
        }
        if(status == 409){
            $('#m-alert').html(event.detail[2].response)
            $('#TipModal').modal({show: true})
        }
    }).on('ajax:send', event => {
    }).on('ajax:complete', event => {
        console.log(event.detail)
    });

    $('.form_with[data-remote="true"]').on('ajax:error', function(event) {
        $('#m-alert').html(event.detail[2].response)
        $('#TipModal').modal({backdrop: false})
        $('#TipModal').modal('show')
        $('#m-loader').hide()
        $('input[type="submit"]').show()
    }).on('ajax:send', event => {
        $('input[type="submit"]').hide()
        $('#m-loader').show()
    }).on('ajax:complete', event => {
        pp(event.detail)
        // $('#m-loader').hide()
        // $('input[type="submit"]').show()
    });
}
window.initialize_a_remote = function(){
    $('a[data_ajax="true"]').unbind( "click" );
    $('a[data_ajax="true"]').on('click', e => {
        let url = e.currentTarget.href
        e.preventDefault()
        window.jq_confirm = $.dialog({
            title: '查看',
            // content: 'url:' + url,
            onContentReady: function () {
                initialize_from_remote()
            },
            content: function(){
                var self = this;
                return $.ajax({
                    url: url,
                    method: 'get'
                }).done(function (response) {
                    self.setContent(response);
                    // initialize_from_remote()
                    // self.setContentAppend('<br>Version: ' + response.version);
                    // self.setTitle(response.name);
                }).fail(function(){
                    self.setContent('权限不足, 或其他错误');
                });
            },
            columnClass: 'large',
        })

    })
}
document.addEventListener("turbolinks:load", function() {
    $('[data-toggle="tooltip"]').tooltip()
    console.log('load')

    initialize_from_remote()
    initialize_a_remote()
})

// select render
window.global_render = function(render_function, data, target){
    let renders = {
        sales_orders: function(){
            console.log(data)
            let html = ''
            for(let item of data){
                html += `<label>${item.name}</label> <input placeholder="${item.unit} (可用${item.total})" max=${item.total} type='number' name="sales_order[items][${item.id}]" /><br/>`
            }
            target.innerHTML = html
        },
        patch_order_inputs: function(){
            console.log(data)
            let html = ''
            for(let item of data){
                html += `<label>${item.name}</label> <input placeholder="${item.unit}" max=${item.total} type='number' name="patch_order[items][${item.id}]" /> <br/>`
            }
            target.innerHTML = html
        },
        transshipment_inputs: function(){
            console.log(data)
            let html = ''
            for(let item of data){
                html += `<label>${item.name}</label> <input placeholder="${item.unit}" max=${item.total} type='number' name="transshipment[items][${item.id}]" /> <br/>`
            }
            target.innerHTML = html
        },
        recycling_product_inputs: function(){
            console.log(data)
            let html = ''
            for(let item of data){
                html += `<label>${item.name}</label> <input placeholder="${item.unit}" max=${item.total} type='number' name="recycling[items][${item.id}]" />
<input type='date' name="recycling[item_date][${item.id}]" />
<br/>`
            }
            target.innerHTML = html
        },
        order_product_inputs: function(){
            console.log(data)
            let html = ''
            for(let item of data){
                html += `<label>${item.name}</label> <input placeholder="${item.unit}" max=${item.total} type='number' name="order[items][${item.id}]" /> <br/>`
            }
            target.innerHTML = html
        },
        product_inputs: function(){
            console.log(data)
            let html = ''
            for(let item of data){
                html += `<input placeholder="${item.name}" max=${item.total} type='number' name="order[items][${item.id}]" /> 箱<br/>`
            }
            target.innerHTML = html
        }
    }
    renders[render_function]()
}
