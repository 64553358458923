import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["output", "input", "code", "number", "progress"]

    _codes = []
    _current_num = 0
    _order_id = ''
    _klass = ''
    connect() {
        this._order_id = this.data.get('id')
        this._klass = this.data.get('klass')
        console.log(this._codes)
    }

    submit(e){
        e.preventDefault()
        console.log(this.codeTarget.value)
      this.render(this.codeTarget.value.split('/').pop())
        this.codeTarget.value = ''
    }

    render(code){
        if(this._codes.includes(code)){
            $.alert('重复录入')
            return
        }
        var self = this
        $.get('/trace_code/verify/' + code + `?klass=${this._klass}`,
              {id: this._order_id},
              (data) => {
                  console.log(data)
                  if(data.error){
                      $.alert(data.error)
                      return
                  }
                  self.progressTargets.forEach( target => {
                      if(target.getAttribute('data-product-id') == data.product_id){
                          target.value = target.value + data.total
                          let badge = $("#product_"+data.product_id)
                          badge.html(target.value)
                          if(target.value == target.getAttribute('max')){
                              badge.addClass('badge-success')
                              badge.removeClass('badge-dark')
                          }else{
                              badge.addClass('badge-dark')
                              badge.removeClass('badge-success')
                          }

                      }
                  })
        })
        return
        // let number = 0
        // if(this.data.get('wholesale') == 'true'){
        //     number = this._current_num + this.data.get('carton').to_i()
        // }else{
        //     number = this._current_num + 1
        // }
        // if(this.data.get('quantity') < number){
        //     alert('超出发货数量')
        //     return
        // }
        // this._current_num = number

        // this._codes.push(code)
        // console.log(this._codes)

        // this.numberTarget.innerHTML = this._current_num
        // $(`<input value=${code} class='m-scanner-codes' name='codes[]' readonly=true />`).appendTo(this.outputTarget)

    }
}
