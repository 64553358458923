import { Controller } from "stimulus"

// 下拉菜单多级联动
// 数据获取接口
// data-select-url = '/products.json?param='
// 可设置其他方法处理返回数据
// data-render="other_method"
export default class extends Controller {
    static targets = ["trigger", "selector", "nextContent" ]

    _current_index = 0
    _selector_size = 1
    initialize(){
        this._selector_size = this.selectorTargets.size()
    }

    connect() {
        console.log(this._selector_size)
        this.selectorTargets.forEach((el,i)=>{
            el.addEventListener('change', e => {
                this.fetch_data(i+1, el.value)
            } )
        })
        this.fetch_data(0, this.triggerTarget.value)
    }

    update(e) {
        this.fetch_data(0, e.target.value)
    }
    update_next(e) {
        this.fetch_data(this._url_path + e.target.value + '.json')
    }

    fetch_data(index, id){
        console.log(index)
        if(index == this._selector_size){
            return
        }
        let target = this.selectorTargets[index]
        let url = target.getAttribute('data-select-url') + id
        fetch(url,{
            method: 'GET',
            credentials: 'include'
        }).then(function(response){
            return response.json();
        }).then( data => {
            let render_function = target.getAttribute('data-render')
            if(render_function){
                console.log(render_function)
                global_render(render_function, data, target)
            }else{
                target.innerHTML = this.render(data, target.getAttribute('data-select-value'))
            }

            index++
            console.log(data.size())
            if(data.size() < 1){
                return
            }
            this.fetch_data(index, target.getAttribute('data-select-value'))
        })
    }
    render(items, selected = false){
        let options = '<option value="">--选择--</option>'
        for(let item of items){
            if(selected && selected == item.id){
                options += `<option value=${item.id} selected>${item.name}</option>`
                continue
            }
            options += `<option value=${item.id}>${item.name}</option>`
        }
        return options
    }
}
