import { Controller } from "stimulus"

export default class extends Controller {
    static targets = []

    connect() {
    }
    picking(e){
        let url = this.data.get("url")
        $.ajax({
            method: 'GET',
            url: url,
        }).done(function(res){
            if(res.type == 'bonus_point'){
                $.alert({
                    title: '恭喜中奖!',
                    content: res.message + ' 可以前往积分商城消费',
                    buttons: {
                        confirm: {
                            text: 'ok',
                            action: function(){
                                location = res.redirect_url
                            }

                        }
                    }
                })
                return
            }
            if(res.type == 'red_pack'){
                $.alert({
                    title: '恭喜中奖!',
                    content: res.message + ' 稍后将直接发送给您, 请注意领取!',
                    buttons: {
                        confirm: {
                            text: 'ok',
                            action: function(){
                                location = res.redirect_url
                            }

                        }
                    }
                })
                return
            }
            $.alert({
                title: '恭喜中奖!',
                content: res.message,
                buttons: {
                    confirm: {
                        text: '存入卡包',
                        action: function(){
                            location = res.redirect_url
                        }

                    }
                }
            });
        })
    }
}
