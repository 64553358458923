import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for ShoppingReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
    add_cart(e) {
        e.preventDefault()
        let current_offset = $(e.currentTarget).offset()
        let target_offset = $('#shopping-cart').offset()
        $('#add_animator').offset(current_offset)
        $('#shopping-cart').animate({
            fontSize: 10
        }, 'fast').animate({
            fontSize: 25
        }, 500, ()=>{
            this.stimulate('ShoppingReflex#add_cart', this.data.get("value"))
        })

        // $('#add_animator').show().animate({
        //     left: target_offset.left,
        //     top: target_offset.top
        // }, 'fast', () => {
        //     $('#add_animator').hide()
        //     // this.stimulate('ShoppingReflex#add_cart', this.data.get("value"))

        // } )

    }
}
