import consumer from "./consumer"

consumer.subscriptions.create("UpdateStockChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
      pp(data)
      if(data.state == 'error' || data.state == 'complete'){
          $.alert(data.msg)
          return
      }

      $('#job_progress').css({width: data.progress})
  }
});
