import consumer from "./consumer"

consumer.subscriptions.create({channel: "ProgressChannel"}, {
  connected() {
    // Called when the subscription is ready for use on the server
      console.log('cable')
  },

  disconnected() {
      console.log('disconnected')
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
      console.log(data)
      console.log('receive')
  }
});
