import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for SelectRegionReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
    static targets = [ "city" ]
    // connect () {
    //     super.connect();
    //     pp(this.cityTarget.value)
    //     this.stimulate('SelectRegionReflex#city', this.cityTarget.value )

    // }
    get_region(e) {
        pp(e.target.value)
        this.stimulate('SelectRegionReflex#city', e.target.value )
    }
}
